import React from "react";
import subscribeStyles from "./subscribe.module.scss";
import ConvertKitForm from "convertkit-react";

const Subscribe = () => {
  const MY_FORM_ID = 1774041;
  return (
    <div className={subscribeStyles.section}>
      <div className={subscribeStyles.container}>
        <h3>Don’t miss a good one</h3>
        <h4>Get new Notion covers straight to your inbox.</h4>
        <ConvertKitForm formId={MY_FORM_ID} />
        <p>No spam, we promise.</p>
      </div>
    </div>
  );
};

export default Subscribe;
