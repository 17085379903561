import React from "react"
import footerStyles from "./footer.module.scss"
import ShareButtons from "./shareButtons"

const Footer = () => {

  return (
    <div className={footerStyles.footer}>
      <div className={footerStyles.container}>
        <div className={footerStyles.shareButtons}>
          <h3>Share the love</h3>
          <ShareButtons title="Aesthetic Notion Covers" url="https://www.notioncovers.com" twitterHandle="@amypeniston" tags="#notiontwt" />
        </div>
      </div>
    </div>
  )
}

export default Footer
