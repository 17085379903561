import React from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import Subscribe from "../components/subscribe"
import layoutStyles from "./layout.module.scss"

const Layout = ({ children }) => {
  return (
    <>
    <div className={layoutStyles.banner}><strong>New!</strong> Personalize your pages with <a href="https://minimalicons.design/">210 custom icons + matching covers for Notion</a></div>
    <Header />
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <div className={layoutStyles.body}>
          {children}
        </div>
      </div>
    </div>
    <Subscribe />
    <Footer />
    </>
  )
}

export default Layout
