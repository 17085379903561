import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import headerStyles from "./header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.headerContent}>
        <Link to="/" className={headerStyles.siteName}>
          <img className={headerStyles.logo} src="/images/logo.png" alt="Notion Covers Logo"/>
          <span>{data.site.siteMetadata.title}</span>
        </Link>
        <nav className={headerStyles.nav}>
          <ul className={headerStyles.navList}>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.navItemActive}
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.navItemActive}
                to="/how-to-use"
              >
                How to Use
              </Link>
            </li>
            <li>
              <Link
                className={headerStyles.navItem}
                activeClassName={headerStyles.navItemActive}
                to="/faqs"
              >
                FAQs
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
